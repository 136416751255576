import { defineStore } from 'pinia'
import { ref } from 'vue'

export const useBroadCastingStore = defineStore('broadcasting', () => {
    const latestStatus = ref(null);
    const latestPayload = ref({ });

    const isConnected = ref(false);
    const isConnecting = ref(false);
    const connectionStatus = ref(null);

    const setStatus = (newStatus, newPayload) => {
        latestStatus.value = newStatus;
        latestPayload.value = newPayload;

        switch (newStatus) {
            case 'connecting':
                isConnected.value = false;
                connectionStatus.value = 'connecting';
                isConnecting.value = true;
                break;
            case 'connected':
                isConnected.value = true;
                connectionStatus.value = 'connected';
                isConnecting.value = false;
                break;
            case 'unavailable':
                isConnected.value = false;
                connectionStatus.value = 'unavailable';
                isConnecting.value = false;
                break;
            case 'failed':
                isConnected.value = false;
                connectionStatus.value = 'failed';
                isConnecting.value = false;
                break;
            case 'disconnected':
                isConnected.value = false;
                connectionStatus.value = 'disconnected';
                isConnecting.value = false;
                break;
            case 'message':
                isConnected.value = true;
                isConnecting.value = false;
                break;
        }
    }

    return {
        latestStatus,
        latestPayload,
        isConnected,
        connectionStatus,
        setStatus
    }
})
