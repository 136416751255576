import './bootstrap';
import '../css/app.css';

import { createApp, h } from 'vue';
import { createInertiaApp } from '@inertiajs/vue3';
import { createPinia } from 'pinia';
import { resolvePageComponent } from 'laravel-vite-plugin/inertia-helpers';
import { broadcasting } from '@/Plugins/Broadcasting.js';
import { ZiggyVue } from 'ziggy-js';
import PrimeVue from 'primevue/config';
import Notifications from 'notiwind'

const pinia = createPinia()

createInertiaApp({
    title: (title) => `${title} - Ciekeboe`,
    resolve: (name) => resolvePageComponent(`./Pages/${name}.vue`, import.meta.glob('./Pages/**/*.vue')),
    setup({ el, App, props, plugin }) {
        return createApp({ render: () => h(App, props) })
            .use(plugin)
            .use(PrimeVue)
            .use(Notifications)
            .use(pinia)
            .use(ZiggyVue, Ziggy)
            .use(broadcasting)
            .mount(el);
    },
    progress: {
        delay: 200,
        color: '#FFDD0E',
    },
});
