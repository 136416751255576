import { useBroadCastingStore } from '@/Stores/Broadcasting.js'
import Pusher from 'pusher-js'
import Echo from 'laravel-echo'

const broadcasting = {
    install() {
        window.Pusher = Pusher;

        window.Echo = new Echo({
            broadcaster: 'reverb',
            key: import.meta.env.VITE_REVERB_APP_KEY,
            wsHost: import.meta.env.VITE_REVERB_HOST,
            wsPort: import.meta.env.VITE_REVERB_PORT,
            wssPort: import.meta.env.VITE_REVERB_PORT,
            forceTLS: (import.meta.env.VITE_REVERB_SCHEME ?? 'https') === 'https',
            enabledTransports: ['ws', 'wss'],
        });

        const events = [
            'connecting',
            'connected',
            'unavailable',
            'failed',
            'disconnected',
            'message',
        ];

        const store = useBroadCastingStore();

        events.forEach(event => {
            window.Echo.connector.pusher.connection.bind(event, payload => {
                if(import.meta.env.DEV) {
                    console.log(`Broadcasting ${event}`, payload);
                }

                store.setStatus(event, payload);
            });
        });
    }
}

export { broadcasting }
